<template>
    <div class="instrument-page" v-if="instrument">
        <div style="width:100%;">
            <json-viewer :value="instrument"></json-viewer>
            <json-viewer :value="subdata" v-if="subdata"></json-viewer>
            <json-viewer :value="fields"></json-viewer>
            <json-viewer :value="subfields" v-if="subfields"></json-viewer>
        </div>
    </div>
</template>
<script>

export default {
    props: {
        isin: {
            required: true,
        },
    },

    data() {
        return {
            asset      : 'future',
            instrument : undefined,
            fields     : undefined,
            subfields  : undefined,
            subdata    : undefined,
        }
    },

    mounted() {
        if (this.asset && this.isin) {
            this.$store.dispatch('instrument/getInstrumentFull', {
                isin: this.isin,
                asset: this.asset
            }).then((instrument_response) => {
                this.instrument = instrument_response.instrument
                this.fields = instrument_response.fields
                this.subfields = instrument_response.subfields
                this.subdata = instrument_response.subdata
                this.$emit('tab-title-change', this.instrument.name)
            })
        }
    },
}
</script>
